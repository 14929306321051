// Modules
import parse from 'html-react-parser'
import { Card, CardBody, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
// Helpers
import { getBadgeImageUrl } from '../../../UpsertBadge/helpers/urlFormatter'

const Details = ({ translate, badge }) => {
  return (
    <div className="badges-details-section">
      <div className="detail-badge-left">
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.name')}</span>
                <p className="scroll">{badge.name}</p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.image')}</span>
                <div className="img-data">
                  {Object.keys(badge).length !== 0 ? (
                    <img
                      src={getBadgeImageUrl(badge?.image)}
                      className="img-fluid"
                      alt={badge.name}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.link')}</span>
                <a
                  href={getBadgeImageUrl(badge?.image)}
                  target="_blank"
                  rel="noreferrer"
                  className="text pointer">
                  {badge.image}
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.chamberName')}</span>
                <p className="scroll">{badge.chamberName}</p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.issuerName')}</span>
                <p className="scroll">{badge.issuerName ? badge.issuerName : badge.chamberName}</p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.description')}</span>
                <p className="scroll">{badge.description && parse(badge.description)}</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="detail-badge-right">
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.criteria')}</span>
                <p className="scroll">
                  {badge.criteriaDescription && parse(badge.criteriaDescription)}
                </p>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <span className="span-10">{translate('badges.details.criteriaUrl')}</span>
                <a className="text" href={badge.criteriaURL} target="_blank" rel="noreferrer">
                  {badge.criteriaURL}
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

Details.propTypes = {
  translate: PropTypes.func,
  badge: PropTypes.object
}

export default Details
