// Modules
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
// Components
import { HeaderTitle } from '../../../components'
import AwardsForm from './AwardsForm'

const AwardsFormMain = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const badgeID = location.state.id

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>IHK - Badge Vergabe</title>
      </Helmet>
      <section className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('award-badge-page.head.title')} />
          <AwardsForm t={t} badgeID={badgeID} />
        </div>
      </section>
    </>
  )
}

export default AwardsFormMain
