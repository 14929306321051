// Modules
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
// Reactstrap
import { CardBody } from 'reactstrap'

const IssuerCard = ({ issuer }) => {
  const { t } = useTranslation()
  const availableBadges = issuer.badgeCount
  const availableAwards = issuer.awardsCount
  return (
    <CardBody>
      <div className="card-text-data">
        <div className="head-div">
          <ReactTooltip place="top" type="dark" />
          <h3 data-tip={issuer.name} className="chamber">
            {issuer.name}
          </h3>
        </div>
        <p>{parse(issuer.description)}</p>
        <span className="mix">
          <div className="badge-award-counter-main">
            <div className="badge-award-counter-inner first">
              <h3>
                {availableBadges}
                {availableBadges > 1 ? (
                  availableBadges <= 0 ? (
                    <span>{t('badge-card.cards.badge-text')}</span>
                  ) : (
                    <span>{t('badge-card.cards.badges-text')}</span>
                  )
                ) : (
                  <span>{t('badge-card.cards.badge-text')}</span>
                )}
              </h3>
            </div>

            <div className="badge-award-counter-inner">
              <h3>
                {availableAwards}
                {availableAwards > 1 ? (
                  availableAwards <= 0 ? (
                    <span>{t('badge-card.cards.award-text')}</span>
                  ) : (
                    <span>{t('badge-card.cards.awards-text')}</span>
                  )
                ) : (
                  <span>{t('badge-card.cards.award-text')}</span>
                )}
              </h3>
            </div>
          </div>
        </span>
      </div>
    </CardBody>
  )
}

IssuerCard.propTypes = {
  issuer: PropTypes.object.isRequired
}

export default IssuerCard
