// Modules
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// Helpers
import { getLocalStorage } from '../../../../helpers/localStorage'
// Constans
import { routerPath } from '../../../../constants/constants'

const ADMIN_PRIVILEGES = ['superuser', 'dihkadmin', 'dihkemployee']

const useAuthRedirection = (auth, issuers, setState, setListIssuers, issuersLoop) => {
  const history = useHistory()

  const redirectToOrderBadges = () => {
    history.push({
      pathname: routerPath.ORDER_BADGES
    })
  }

  const redirectToIssuerDetails = (issuer) => {
    history.push({
      pathname: routerPath.ISSUER_DETAILS,
      state: {
        id: issuer.id,
        name: issuer.name
      }
    })
  }

  const handleRoleBasedRedirection = () => {
    if (!ADMIN_PRIVILEGES.includes(auth?.role)) {
      redirectToOrderBadges()
    } else {
      const storedFilters = getLocalStorage('searchFilters')
      if (storedFilters) {
        setState((prevPageData) => ({
          ...prevPageData,
          searchFilters: storedFilters
        }))

        const filteredIds = storedFilters.map((ele) => ele.id)
        const filteredIssuers = issuers?.filter((issuer) => filteredIds.includes(issuer.id))
        setListIssuers(filteredIssuers)
      } else {
        setListIssuers(issuersLoop)
      }
    }
  }

  const handleChamberBasedRedirection = () => {
    const matchedIssuer = issuers?.find(
      (issuer) => issuer?.name === auth?.chamber || issuer?.chamberId === auth?.chamberId
    )

    if (matchedIssuer) {
      redirectToIssuerDetails(matchedIssuer)
    } else {
      redirectToOrderBadges()
    }
  }

  useEffect(() => {
    if (!auth?.chamber) {
      handleRoleBasedRedirection()
    } else {
      handleChamberBasedRedirection()
    }
  }, [auth, issuers])
}

export default useAuthRedirection
