// API
import api from '../helpers/api'
// Reducer
import { endSpinner, startSpinner } from '../redux/reducers/spinnerReducer'
// Helpers
import { createFormData } from '../helpers/formData'

export const SAVE_AWARD_RECIPIENTS_DATA = 'SAVE_AWARD_RECIPIENTS_DATA'
export const SAVE_AWARD_DATA = 'SAVE_AWARD_DATA'
export const SET_CAN_SUBMIT_CSV_FILE = 'SET_CAN_SUBMIT_CSV_FILE'
export const SET_EMPTY_DATA_FLAG_BADGES = 'SET_EMPTY_DATA_FLAG_BADGES'
export const SET_LOADING = 'SET_LOADING'

const defaultState = {
  awardRecipients: [],
  awardDetails: {},
  uploadedCSV: {},
  isDataEmpty: false,
  loading: true
}

// Reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_AWARD_RECIPIENTS_DATA: {
      return Object.assign({}, state, {
        ...state,
        awardRecipients: action.payload
      })
    }
    case SAVE_AWARD_DATA: {
      return Object.assign({}, state, {
        ...state,
        awardDetails: action.payload
      })
    }
    case SET_CAN_SUBMIT_CSV_FILE: {
      return Object.assign({}, state, {
        ...state,
        uploadedCSV: action.payload
      })
    }
    case SET_EMPTY_DATA_FLAG_BADGES: {
      return Object.assign({}, state, {
        ...state,
        isDataEmpty: action.payload
      })
    }
    case SET_LOADING: {
      return Object.assign({}, state, {
        ...state,
        loading: action.payload
      })
    }
    default:
      return state
  }
}

export const saveAwardRecipients = (awardRecipients) => {
  return {
    type: SAVE_AWARD_RECIPIENTS_DATA,
    payload: awardRecipients
  }
}

export const saveAwardDetails = (awardDetails) => {
  return {
    type: SAVE_AWARD_DATA,
    payload: awardDetails
  }
}

export const uploadedCSV = (uploadedCSV) => {
  return {
    type: SET_CAN_SUBMIT_CSV_FILE,
    payload: uploadedCSV
  }
}

export const saveDataEmpty = (isDataEmpty) => {
  return {
    type: SET_EMPTY_DATA_FLAG_BADGES,
    payload: isDataEmpty
  }
}

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const getBadgeAwardRecipients = async ({ id, dispatch }) => {
  return await api
    .get(`/badge/issue/get?badgeID=${id}`)
    .then((response) => {
      const { data, status } = response.data
      dispatch(saveAwardRecipients(data))
      if (status === 200) {
        dispatch(saveDataEmpty(true))
        dispatch(setLoading(false))
      }
    })
    .catch((e) => {
      console.log('Error calling getBadgeAwardRecipients: ', e)
    })
}

export const getRecepientAwardDetails = async ({ id, dispatch }) => {
  return await api
    .get(`/badge/issue/get/${id}`)
    .then((response) => {
      const { data, status } = response.data
      dispatch(saveAwardDetails(data))
      if (status === 200) {
        dispatch(saveDataEmpty(true))
        dispatch(setLoading(false))
      }
    })
    .catch((e) => {
      console.log('Error calling getRecepientAwardDetails: ', e)
    })
}

export const awardBadgeToRecipient = async ({ data, successCb, errorCb }) => {
  return await api
    .post('badge/issue/single', data)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling awardBadgeToRecipient: ', e)
      errorCb && errorCb(e)
    })
}

export const uploadCSVFile = async ({ data, errorCb, successCb, dispatch }) => {
  const dataObj = createFormData(data)

  return await api
    .postFile({ path: 'badge/issue/upload-csv', data: dataObj })
    .then((response) => {
      successCb && successCb(response.data)
      dispatch(uploadedCSV(response.data.data.csv))
    })
    .catch((e) => {
      console.log('Error calling uploadCSVFile: ', e)
      errorCb && errorCb(e.response.status)
    })
}

export const submitCSVFile = async ({ data, successCb, errorCb, dispatch }) => {
  dispatch(startSpinner())
  return await api
    .post('badge/issue/submit-csv', data)
    .then((response) => {
      successCb && successCb(response.data)
      dispatch(endSpinner())
      dispatch(uploadedCSV({}))
    })
    .catch((e) => {
      console.log('Error calling submitCSVFile: ', e)
      errorCb && errorCb(e)
    })
}

export const revokeRecipientAward = async ({ id, successCb, errorCb }) => {
  return await api
    .post(`badge/revoke/${id}`)
    .then(() => {
      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling revokeRecipientAward: ', e)
      errorCb && errorCb(e)
    })
}

export const sendEmailToRecepient = async ({ id, successCb }) => {
  return await api
    .post(`/badge/notify/recepient/${id}`)
    .then((response) => {
      successCb && successCb(response)
    })
    .catch((e) => {
      console.log('Error calling sendEmailToRecepient: ', e)
    })
}
