// Modules
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
// Components
import { ActionButton, PaginationLoad, Popup } from '../../../components'
import Details from './components/Details'
// Utils
import { deleteBadge } from '../../../ducks/Badges.ducks'
import { hasPermission } from '../../../helpers/permissions'
import { routerPath, PATH, ACTION } from '../../../constants/constants'
// Assets
import IconPen from '../../../assets/image/icon-pen.svg'
import IconTrash from '../../../assets/image/icon-delete.svg'
import IconBack from '../../../assets/image/icon-left.svg'
import IconSubstract from '../../../assets/image/icon-substract-1.svg'
import IconToastError from '../../../assets/image/icon-toast-error.png'
import IconToastSuccess from '../../../assets/image/icon-toast-success.svg'

const BadgeDetails = ({ t, auth, badge }) => {
  const history = useHistory()
  const location = useLocation()

  const [loader, setLoader] = useState(false)
  const [modal, setModal] = useState({
    cancel: false,
    delete: false
  })

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoader(true)
    }, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [badge])

  const handleOpenBadgeRecepients = () => {
    history.push({
      pathname: routerPath.BADGE_RECIPIENT,
      state: {
        id: location.state.id,
        chamberId: location.state.chamberId,
        chamberName: location.state.chamberName
      }
    })
  }

  const handleEditBadge = () => {
    history.push({
      pathname: routerPath.EDIT_BADGE,
      state: {
        id: location.state.id,
        chamberId: badge.chamberID,
        chamberName: badge.chamberName
      }
    })
  }

  const handleDeletePopup = () => {
    setModal((prevPageData) => ({
      ...prevPageData,
      delete: true
    }))
  }

  const handleBadgeDelete = () => {
    deleteBadge({
      id: badge?.id,
      successCb: () => {
        history.replace({
          pathname: routerPath.ISSUER_DETAILS,
          state: {
            id: location.state.chamberId,
            name: location.state.chamberName
          }
        })
        toast.success(t('badges.actions.messages.deleted'), {
          icon: <img src={IconToastSuccess} className="img-fluid" alt="images" />
        })
      },
      errorCb: (status) => {
        if (status === 400) {
          setModal((prevPageData) => ({
            ...prevPageData,
            cancel: true,
            delete: false
          }))
        }
        toast.error(t('badges.actions.messages.error'), {
          icon: <img src={IconToastError} className="img-fluid" alt="images" />
        })
      }
    })
  }

  return (
    <>
      <div className="breadcrumb-section mb-40">
        <div className="back-section">
          <div className="back-icon">
            {auth !== null && (
              <div className="icon">
                <Link
                  to={{
                    pathname: routerPath.ISSUER_DETAILS,
                    state: {
                      id: badge.chamberID,
                      name: badge.chamberName
                    }
                  }}>
                  <img src={IconBack} className="img-fluid" alt="icon-back" />
                </Link>
              </div>
            )}
            <div className="title">
              <h3>{t('badges.details.breadcrumb.title')}</h3>
            </div>
          </div>
          <p className="span-10 mb-0 text-uppercase">{t('badges.details.breadcrumb.info')}</p>
        </div>
        <div className="search-action-buttons-section pull-right mt-20">
          <div className="action-buttons">
            {hasPermission(auth?.role, PATH.AWARDS, ACTION.VIEW) && (
              <ActionButton icon={IconSubstract} triggerFunction={handleOpenBadgeRecepients} />
            )}
            {hasPermission(auth?.role, PATH.BADGES, ACTION.UPDATE) && (
              <ActionButton icon={IconPen} triggerFunction={handleEditBadge} />
            )}
            {hasPermission(auth?.role, PATH.BADGES, ACTION.DELETE) && (
              <ActionButton icon={IconTrash} triggerFunction={handleDeletePopup} />
            )}
          </div>
        </div>
      </div>
      {loader ? <Details translate={t} badge={badge} /> : <PaginationLoad />}

      <Popup
        show={modal.cancel}
        changeSetShow={() =>
          setModal((prevPageData) => ({
            ...prevPageData,
            cancel: !modal.cancel
          }))
        }
        changeSetShowToFalse={() =>
          setModal((prevPageData) => ({
            ...prevPageData,
            cancel: false
          }))
        }
        headingText={t('badges.form.modal.cancel-title')}
        paragraphText={t('badges.form.modal.cancel-paragraph')}
      />

      <Popup
        show={modal.delete}
        onDelete={handleBadgeDelete}
        changeSetShow={() =>
          setModal((prevPageData) => ({
            ...prevPageData,
            delete: !modal.delete
          }))
        }
        changeSetShowToFalse={() =>
          setModal((prevPageData) => ({
            ...prevPageData,
            delete: false
          }))
        }
        headingText={t('badges.form.modal.delete-title')}
        paragraphText={t('badges.form.modal.delete-paragraph')}
        buttonIsDisabled={true}
        buttonText={t('badges.form.modal.delete-button')}
      />
    </>
  )
}

BadgeDetails.propTypes = {
  badge: PropTypes.object
}

export default BadgeDetails
