// Modules
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getChambers, getIssuers } from '../../../ducks/Issuers.ducks'
// Utils
import { hasPermission } from '../../../helpers/permissions'
import { PATH, ACTION } from '../../../constants/constants'
// Components
import { HeaderTitle, Loader } from '../../../components'
import UpsertIssuer from './UpsertIssuer'
// Style
import './UpsertIssuer.scss'

const UpsertIssuerMain = ({ getIssuers, getChambers }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { loading, chambers, issuersList } = useSelector((state) => state.issuers)

  const { authState: { auth } = {} } = useAuth()

  const { chamberId } = location.state || {}
  const editMode = !!chamberId

  const issuer = issuersList?.find((issuer) => issuer.id === chamberId)

  useEffect(() => {
    if (!hasPermission(auth?.role, PATH.ISSUERS, ACTION.CREATE)) {
      history.replace(`/?token=${token}`)
      return
    }

    const asyncApiCall = async () => {
      await Promise.all([getIssuers(), getChambers()])
    }

    asyncApiCall()
  }, [])

  return (
    <>
      <Helmet>
        <title>IHK - Aussteller anlegen/bearbeiten</title>
      </Helmet>
      <section className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('issuers.general.header-title')} />

          {editMode ? (
            issuer && (
              <UpsertIssuer
                t={t}
                auth={auth}
                chambers={chambers}
                issuer={issuer}
                editMode={editMode}
              />
            )
          ) : (
            <UpsertIssuer t={t} auth={auth} chambers={chambers} editMode={editMode} />
          )}

          {loading && <Loader />}
        </div>
      </section>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getIssuers,
        getChambers
      },
      dispatch
    )
  }
}

export default connect(null, mapDispatchToProps)(UpsertIssuerMain)
