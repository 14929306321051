// Modules
import { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import Ripples from 'react-ripples'
import { toast } from 'react-toastify'
// Actions
import { revokeRecipientAward, sendEmailToRecepient } from '../../../ducks/Awards.ducks'
// Components
import { PaginationLoad as Loader, Popup, ShareButton } from '../../../components'
import Fields from './components/Fields'
// Helpers
import { hasPermission } from '../../../helpers/permissions'
import { routerPath, PATH, ACTION } from '../../../constants/constants'
// Assets
import { BiShareAlt, BiMailSend, BiTrash, BiMessageCheck, BiArrowBack } from 'react-icons/bi'
import IconToastError from '../../../assets/image/icon-toast-error.png'

export const DELAY = 600

const RecipientDetails = ({ t, auth, awardDetails = {} }) => {
  const history = useHistory()
  const location = useLocation()

  const [showComponent, setShowComponent] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { badge, issuedBadge } = awardDetails

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowComponent(true)
    }, DELAY)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [location.state.id, t, awardDetails])

  const toggleDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState)
  }

  const handleRecipientAwardRevoke = () => {
    revokeRecipientAward &&
      revokeRecipientAward({
        id: issuedBadge?.id,
        successCb: () => {
          toggleDeleteModal()
          history.replace({
            pathname: routerPath.BADGE_RECIPIENT,
            state: {
              id: location.state.badgeId,
              chamberId: location.state.chamberId,
              chamberName: location.state.chamberName
            }
          })
          toast.success(t('badge-details-page.delete-badge-success-massage'), {
            icon: <BiMessageCheck size="20" />
          })
        },
        errorCb: (err) => {
          toast.error(err, {
            icon: <img src={IconToastError} className="img-fluid" alt="images" />
          })
        }
      })
  }

  const handleSendEmailToRecipient = (awardId) => {
    sendEmailToRecepient &&
      sendEmailToRecepient({
        id: awardId,
        successCb: ({ status, data }) => {
          if (status === 200) {
            toast.success(t('awarded-recepient.success-message'), {
              icon: <BiMessageCheck size="20" />
            })
          } else {
            toast.error(data, {
              icon: <img src={IconToastError} className="img-fluid" alt="images" />
            })
          }
        }
      })
  }

  const handleToggleShareStatus = () => {
    setShowShareModal((prevState) => !prevState)
  }

  return (
    <>
      <div className="arrow-data">
        <div className="left-data">
          <div className="head-back-data">
            <div className="back-data">
              <Link
                to={{
                  pathname: routerPath.BADGE_RECIPIENT,
                  state: {
                    id: location.state.badgeId,
                    chamberId: location.state.chamberId,
                    chamberName: location.state.chamberName
                  }
                }}>
                <BiArrowBack size="20" className="default-color" />
              </Link>
            </div>
            <div className="head-data">
              <h3>{t('recipient-details-page.head.back-heading')}</h3>
            </div>
          </div>
          <p className="span-10 mb-0 text-uppercase">
            {t('recipient-details-page.head.back-para')}
          </p>
        </div>
        <div className="right-data">
          <ul>
            {hasPermission(auth?.role, PATH.SINGLE_AWARD, ACTION.SEND_MAIL) && (
              <li>
                <Ripples className="react-icon-48 color-off-white">
                  <Button
                    color="primary"
                    className="btn-icon"
                    onClick={() => {
                      const awardID = issuedBadge?.id
                      handleSendEmailToRecipient(awardID)
                    }}>
                    <BiMailSend size="20" />
                  </Button>
                </Ripples>
              </li>
            )}
            {hasPermission(auth?.role, PATH.SINGLE_AWARD, ACTION.SHARE) && (
              <li>
                <Ripples className="react-icon-48 color-off-white">
                  <Button color="primary" className="btn-icon" onClick={handleToggleShareStatus}>
                    <BiShareAlt size="20" />
                  </Button>
                </Ripples>
              </li>
            )}
            {hasPermission(auth?.role, PATH.SINGLE_AWARD, ACTION.DELETE) && (
              <li>
                <Ripples className="react-icon-48 color-off-white">
                  <Button color="primary" className="btn-icon" onClick={toggleDeleteModal}>
                    <BiTrash size="20" />
                  </Button>
                </Ripples>
              </li>
            )}
          </ul>
        </div>
      </div>
      {showComponent ? <Fields t={t} badge={badge} issuedBadge={issuedBadge} /> : <Loader />}

      <ShareButton
        show={showShareModal}
        changeSetShow={handleToggleShareStatus}
        setShowToFalse={() => {
          setShowShareModal(false)
        }}
        recipient={issuedBadge}
      />

      <Popup
        show={showDeleteModal}
        changeSetShow={toggleDeleteModal}
        changeSetShowToFalse={toggleDeleteModal}
        onDelete={handleRecipientAwardRevoke}
        headingText={t('recipient-list-page.revoke-modal.head')}
        paragraphText={t('recipient-list-page.revoke-modal.text')}
        buttonIsDisabled={true}
        buttonText={t('recipient-list-page.revoke-modal.btn-text')}
      />
    </>
  )
}

export default RecipientDetails
