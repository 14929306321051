// Modules
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Form } from 'reactstrap'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
// Actions
import { awardBadgeToRecipient, submitCSVFile } from '../../../ducks/Awards.ducks'
// Helpers
import { routerPath } from '../../../constants/constants'
import { isEmptyObject } from '../../../helpers/objects'
import { convertDateFormat } from '../../../helpers/dates'
// Components
import AwardFields from './components/AwardFields'
// Assets
import 'flatpickr/dist/themes/material_green.css'
import { BiMessageCheck } from 'react-icons/bi'
import IconToastError from '../../../assets/image/icon-toast-error.png'
import Iconback from '../../../assets/image/icon-left.svg'

function getValueAndTrim(value) {
  return value?.trim()
}

const AwardsForm = ({ t, badgeID }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const formRef = useRef(null)

  const { uploadedCSV } = useSelector((state) => state.awards)

  useEffect(() => {
    formRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const isCsvUploaded = !isEmptyObject(uploadedCSV)

  const handleCancelAction = () => {
    history.push({
      pathname: routerPath.BADGE_RECIPIENT,
      state: {
        id: location.state.id,
        chamberName: location.state.chamberName,
        chamberId: location.state.chamberId
      }
    })
  }

  let validationSchema = null
  {
    !isCsvUploaded &&
      (validationSchema = Yup.object({
        name: Yup.string().required(t('error-msg.required')),
        surname: Yup.string().required(t('error-msg.required')),
        email: Yup.string().email(t('error-msg.invalid-email')).required(t('error-msg.required')),
        startDate: Yup.date().required(t('error-msg.required'))
      }))
  }

  const formik = useFormik({
    initialValues: { name: '', surname: '', email: '', startDate: undefined },
    validationSchema,
    onSubmit: async (values) => {
      const startDate = convertDateFormat(values.startDate)
      const recepientTitle = values.professionTitle || ''

      const recepientData = {
        firstName: getValueAndTrim(values.name),
        lastName: getValueAndTrim(values.surname),
        email: getValueAndTrim(values.email),
        badgeID: location.state.id,
        issueDate: startDate
      }

      if (recepientTitle) {
        recepientData.professionTitle = recepientTitle
      }

      if (!isCsvUploaded) {
        if (values.endDate) {
          recepientData.expiryDate = convertDateFormat(values.endDate)
        }

        awardBadgeToRecipient &&
          awardBadgeToRecipient({
            data: recepientData,
            successCb: () => {
              toast.success(t('awarded-recepient.success-message'), {
                icon: <BiMessageCheck size="20" />
              })
              history.replace({
                pathname: routerPath.BADGE_RECIPIENT,
                state: {
                  id: location.state.id,
                  chamberName: location.state.chamberName,
                  chamberId: location.state.chamberId
                }
              })
            },
            errorCb: (e) => {
              toast.error(e, {
                icon: <img src={IconToastError} className="img-fluid" alt="images" />
              })
            }
          })
      } else {
        const data = {
          badgeCSVID: uploadedCSV.id
        }
        submitCSVFile &&
          submitCSVFile({
            data,
            dispatch,
            successCb: () => {
              toast.success(t('awards.actions.messages.success-csv-upload-recipients'), {
                icon: <BiMessageCheck size="20" />
              })
              history.replace({
                pathname: routerPath.BADGE_RECIPIENT,
                state: {
                  id: location.state.id,
                  chamberName: location.state.chamberName,
                  chamberId: location.state.chamberId
                }
              })
            },
            errorCb: (e) => {
              toast.error(e, {
                icon: <img src={IconToastError} className="img-fluid" alt="images" />
              })
            }
          })
      }
    }
  })

  return (
    <>
      <div className="arrow-data">
        <div className="left-data">
          <div className="head-back-data">
            <div className="back-data">
              <Link
                to={{
                  pathname: routerPath.BADGE_RECIPIENT,
                  state: {
                    id: location.state.id,
                    chamberName: location.state.chamberName,
                    chamberId: location.state.chamberId
                  }
                }}>
                <img src={Iconback} className="img-fluid" alt="icon-back" />
              </Link>
            </div>
            <div className="head-data">
              <h3>{t('award-badge-page.head.back-heading')}</h3>
            </div>
          </div>
          <p className="span-10 mb-0 text-uppercase">{t('award-badge-page.head.back-para')}</p>
        </div>
      </div>

      <div className="award-card-data">
        <Card>
          <CardHeader>{t('award-badge-page.card-title')}</CardHeader>
          <CardBody>
            <Form
              onSubmit={(event) => {
                event.preventDefault()
                formik.handleSubmit(event)
              }}>
              <AwardFields
                t={t}
                formik={formik}
                formRef={formRef}
                badgeID={badgeID}
                handleCancelAction={handleCancelAction}
              />
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default AwardsForm
