// Modules
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, Button, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Ripples from 'react-ripples'
// Helpers
import { encryptEmail } from '../../helpers/emails'
// Assets
import { AiOutlineCopy } from 'react-icons/ai'
import IconModalClose from '../../assets/image/icon-dark-close.svg'

const ShareButton = ({ show, changeSetShow, setShowToFalse, recipient }) => {
  const { t } = useTranslation()
  const [isEmailIn, setIsEmailIn] = useState(false)

  const encryptedEmail = encryptEmail({ email: recipient?.email })

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
  }

  useEffect(() => {
    if (!show) {
      setIsEmailIn(false)
    }
  }, [show])

  return (
    <Modal
      isOpen={show}
      toggle={changeSetShow}
      className="modal-dialog-centered common-modal bigs"
      backdropClassName="op-35">
      <ModalBody>
        <div className="close-modal pointer" onClick={setShowToFalse}>
          <img src={IconModalClose} className="img-fluid" alt="images" />
        </div>
        <div className="icon-modal">
          <div className="icon-warning text-primary">
            <AiOutlineCopy size="20" />
          </div>
        </div>
        <div className="title">
          <h3 className="modal-title text-center">{t('copy-modal.copy-header')}</h3>
        </div>
        <div className="my-3 d-flex mx-3">
          <div
            className="span-14"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '5px',
              marginBottom: '5px'
            }}>
            <input
              type="checkbox"
              style={{
                marginTop: '2px',
                marginRight: '5px'
              }}
              id="isEmailIn"
              value={isEmailIn ? 'false' : 'true'}
              onChange={(e) => setIsEmailIn(e.target.value.toLocaleLowerCase() === 'true')}
            />
            {t('recipient-list-page.share-model.validate-with-email')}
          </div>
        </div>
        <Input
          value={`${recipient?.meta?.id}${isEmailIn ? '' : `?id=${encryptedEmail}`}`}
          className="bg-white"
          disabled
        />
        <div className="text-center mt-3">
          <Ripples className="react-12-cut color-off-white">
            <Button
              onClick={() =>
                copyToClipBoard(`${recipient?.meta?.id}${isEmailIn ? '' : `?id=${encryptedEmail}`}`)
              }
              color="primary"
              className="modal-big-btn btn-cu-md">
              {t('copy-modal.copy-btn')}
            </Button>
          </Ripples>
        </div>
      </ModalBody>
    </Modal>
  )
}

ShareButton.propTypes = {
  show: PropTypes.bool.isRequired,
  changeSetShow: PropTypes.func.isRequired,
  setShowToFalse: PropTypes.func.isRequired,
  recipient: PropTypes.object
}

export default ShareButton
