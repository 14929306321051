// Modules
import { Button, Modal, ModalBody } from 'reactstrap'
import Ripples from 'react-ripples'
import PropTypes from 'prop-types'
// Assets
import IconModalClose from '../../assets/image/icon-dark-close.svg'
import IconWarning from '../../assets/image/icon-warning.svg'

const Popup = ({
  show,
  changeSetShow,
  changeSetShowToFalse,
  onDelete,
  headingText,
  paragraphText,
  buttonIsDisabled,
  buttonText
}) => {
  return (
    <Modal
      isOpen={show}
      toggle={changeSetShow}
      className="modal-dialog-centered common-modal"
      backdropClassName="op-35">
      <ModalBody>
        <div className="close-modal pointer" onClick={changeSetShowToFalse}>
          <img src={IconModalClose} className="img-fluid" alt="images" />
        </div>
        <div className="icon-modal">
          <div className="icon-warning">
            <img src={IconWarning} className="img-fluid" alt="images" />
          </div>
        </div>
        <div className="title">
          <h3 className="modal-title text-center">{headingText}</h3>
        </div>
        <p className="span-14 text-center mb-32">{paragraphText}</p>
        {onDelete && (
          <div className="text-center">
            <Ripples
              className={`react-12-cut color-off-white ${buttonIsDisabled ? '' : 'disabled'}`}>
              <Button
                color="primary"
                className="modal-big-btn btn-cu-xl"
                onClick={onDelete}
                disabled={!buttonIsDisabled}>
                {buttonText}
              </Button>
            </Ripples>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  changeSetShow: PropTypes.func.isRequired,
  changeSetShowToFalse: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  headingText: PropTypes.string.isRequired,
  paragraphText: PropTypes.string.isRequired,
  buttonIsDisabled: PropTypes.bool,
  buttonText: PropTypes.string
}

export default Popup
