// Modules
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// Components
import { Card } from '../../../../../components'
// Helpers
import { routerPath } from '../../../../../constants/constants'

const CardListing = ({ badges }) => {
  // Sort the badgesData alphabetically by name
  // Memoize the sorted data to avoid unnecessary sorting on each render
  const sortedData = useMemo(() => {
    return badges.slice().sort((a, b) => a.name.localeCompare(b.name))
  }, [badges])
  return (
    <>
      {sortedData.length !== 0 &&
        sortedData.map((items, i) => (
          <Link
            key={i}
            to={{
              pathname: routerPath.BADGE_DETAILS,
              state: {
                id: items.id,
                chamberId: items.chamberID,
                chamberName: items.chamberName
              }
            }}
            className="card">
            <Card items={items} />
          </Link>
        ))}
    </>
  )
}

CardListing.propTypes = {
  badges: PropTypes.array.isRequired
}

export default CardListing
