// Modules
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { CardBody } from 'reactstrap'
// Helpers
import { convertDateFormat } from '../../helpers/dates'
import { getBadgeImageUrl } from '../../view/Badges/UpsertBadge/helpers/urlFormatter'

const Card = ({ items }) => {
  const { t } = useTranslation()
  const availableAwards = items.awardsCount

  return (
    <CardBody>
      <div className="date-div">
        <div className="date">
          <span>{convertDateFormat(items.createdAt)}</span>
        </div>
        <div className="name">
          <ReactTooltip place="top" type="dark" />
          <p data-tip={items.chamberName} className="chamber">
            {items.chamberName}
          </p>
        </div>
      </div>
      <div className="card-img">
        <img
          src={getBadgeImageUrl(items?.image)}
          className="img-fluid"
          alt={t('badge-card.cards.cards-badge-image-alt')}
        />
      </div>
      <div className="card-text-data">
        <h4>{items.name}</h4>
        <p>{parse(items.description)}</p>
        <span className="mix">
          <h3>
            {availableAwards}
            &nbsp;
            {availableAwards > 1
              ? availableAwards <= 0
                ? t('badge-card.cards.award-text')
                : t('badge-card.cards.awards-text')
              : t('badge-card.cards.award-text')}
          </h3>
        </span>
      </div>
    </CardBody>
  )
}

Card.propTypes = {
  items: PropTypes.object.isRequired
}

export default Card
