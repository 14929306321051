// Modules
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Ripples from 'react-ripples'
import { toast } from 'react-toastify'
// Actions
import { sendEmailToRecepient } from '../../../ducks/Awards.ducks'
// Components
import { EmptyData, PaginationInfiniteScroll, PaginationLoad } from '../../../components'
import { TableListing } from './componets'
// Helpers
import { hasPermission } from '../../../helpers/permissions'
import { routerPath, PATH, ACTION } from '../../../constants/constants'
// Assets
import Iconplus from '../../../assets/image/icon-plus.svg'
import IconBack from '../../../assets/image/icon-back.svg'
import IconToastError from '../../../assets/image/icon-toast-error.png'
import IconToastSuccess from '../../../assets/image/icon-toast-success.svg'

export const RECIPIENTS_PER_PAGE = 20
export const DELAY = 1000

const RecipientsListing = ({ t, auth, recipients, isDataEmpty }) => {
  const location = useLocation()

  const recipientsLoop = recipients.slice(0, RECIPIENTS_PER_PAGE)
  const [listRecipients, setListRecipients] = useState([])
  const [showComponent, setShowComponent] = useState(false)

  const [state, setState] = useState({
    isActive: false,
    hasMore: true,
    modalCancel: false,
    modalDelete: false
  })

  useEffect(() => {
    setListRecipients(recipientsLoop)

    const timeoutId = setTimeout(() => {
      setShowComponent(true)
    }, DELAY)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [recipients])

  const handleLoadMoreRecipients = () => {
    if (listRecipients.length >= recipients.length) {
      setState((prevPageData) => ({
        ...prevPageData,
        hasMore: false
      }))
      return
    }
    const newRecipients = recipients.slice(
      listRecipients.length,
      listRecipients.length + RECIPIENTS_PER_PAGE
    )

    return new Promise((resolve) => {
      setTimeout(() => {
        setListRecipients([...listRecipients, ...newRecipients])
        resolve()
      }, 300)
    })
  }

  const handleSendEmailToRecipient = (awardId) => {
    sendEmailToRecepient &&
      sendEmailToRecepient({
        id: awardId,
        successCb: ({ status, data }) => {
          if (status === 200) {
            toast.success(t('awarded-recepient.success-message'), {
              icon: <img src={IconToastSuccess} className="img-fluid" alt="images" />
            })
          } else {
            toast.error(data, {
              icon: <img src={IconToastError} className="img-fluid" alt="images" />
            })
          }
        }
      })
  }

  const RecipientsListTable = ({ listRecipients, loadMore, hasMore, isDataEmpty }) => (
    <div>
      {showComponent ? (
        listRecipients && listRecipients.length > 0 ? (
          <PaginationInfiniteScroll
            dataLength={listRecipients.length}
            loadMore={loadMore}
            hasMore={hasMore}
            scrollableTargetId="recipientScrollData">
            <TableListing
              t={t}
              auth={auth}
              recipients={listRecipients}
              location={location.state}
              handleSendEmailToRecipient={handleSendEmailToRecipient}
            />
          </PaginationInfiniteScroll>
        ) : (
          isDataEmpty && <EmptyData textId="awards.actions.messages.no-recipients-found" />
        )
      ) : (
        <PaginationLoad />
      )}
    </div>
  )

  return (
    <>
      <div className="arrow-data single">
        <div className="left-data">
          <div className="head-back-data">
            <div className="back-data">
              <Link
                to={{
                  pathname: routerPath.BADGE_DETAILS,
                  state: {
                    id: location.state.id,
                    chamberName: location.state.chamberName,
                    chamberId: location.state.chamberId
                  }
                }}>
                <img src={IconBack} className="img-fluid" alt="icon-back" />
              </Link>
            </div>
            <div className="head-data">
              <h3>{t('recipient-list-page.head.back-heading')}</h3>
            </div>
          </div>
          <p className="span-10 mb-0 text-uppercase">{t('recipient-list-page.head.back-para')}</p>
        </div>
        <div className="right-data">
          <ul>
            <li>
              {hasPermission(auth?.role, PATH.SINGLE_AWARD, ACTION.CREATE) && (
                <Ripples className="react-icon-48 color-off-white">
                  <Link
                    title={t('recipient-list-page.head.add-btn')}
                    to={{
                      pathname: routerPath.AWARD_BADGE,
                      state: {
                        id: location.state.id,
                        chamberName: location.state.chamberName,
                        chamberId: location.state.chamberId
                      }
                    }}
                    className="btn-icon btn btn-primary">
                    <img src={Iconplus} className="img-fluid" alt="icon-plus" />
                  </Link>
                </Ripples>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="badges-details-section table-full">
        <RecipientsListTable
          listRecipients={listRecipients}
          loadMore={handleLoadMoreRecipients}
          hasMore={state.hasMore}
          isDataEmpty={isDataEmpty}
        />
      </div>
    </>
  )
}

export default RecipientsListing
