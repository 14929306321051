// Modules
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getBadges } from '../../../ducks/Badges.ducks'
// Components
import { HeaderTitle } from '../../../components'
import BadgesListing from './BadgesListing'

const BadgesListingMain = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { authState: { auth } = {} } = useAuth()
  const { badges, isDataEmpty } = useSelector((state) => state.badges)

  const chamberId = location.state.id

  useEffect(() => {
    if (!location.state) {
      history.goBack()
    }

    const asyncApiCall = async () => {
      await getBadges({ id: chamberId, dispatch })
    }

    asyncApiCall()
  }, [getBadges])

  return (
    <>
      <Helmet>
        <title>IHK - Aussteller Details</title>
      </Helmet>
      <section id="scrollData" className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('page-title.main-badge')} />
          {badges && <BadgesListing t={t} auth={auth} badges={badges} isDataEmpty={isDataEmpty} />}
        </div>
      </section>
    </>
  )
}

export default BadgesListingMain
